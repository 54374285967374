<template>
  <div>
    <b-modal
      id="modal-add-product-kit"
      title="เพิ่มอุปกรณ์"
      ref="modal-add-product-kit"
      no-close-on-backdrop
      hide-footer
    >
      <h5>เลือกอุปกรณ์</h5>
      <product-suggest @searchSelected="searchSelected"></product-suggest>
      <hr />
      <!-- <h4 class="py-1" v-if="form.name">
        {{ form.name }} - {{ form.rental_price }} บาท/วัน
      </h4> -->
      <h5>ค่าเช่า</h5>
      <b-input-group append="฿" class="mb-1">
        <b-form-input v-model="form.rental_price" />
      </b-input-group>

      <h5>ลำดับที่แสดง</h5>
      <b-input-group class="mb-1">
        <b-form-input v-model="form.sorting_order" />
      </b-input-group>

      <b-button variant="relief-success" @click="add"> เพิ่มอุปกรณ์ </b-button>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BInputGroup,
  BFormGroup,
  BFormInput,
  BAlert,
} from "bootstrap-vue";
import ProductSuggest from "./ProductSuggest.vue";
import _ from "lodash";

export default {
  components: {
    BButton,
    ProductSuggest,
    BInputGroup,
    BFormGroup,
    BFormInput,
    BAlert,
  },
  data() {
    return {
      form: {},
      product_selected: {
        name: null,
      },
    };
  },
  created() {},
  methods: {
    clear(x) {
      this.form = {};
      this.product_selected = {
        name: null,
      };
    },
    searchSelected(x) {
      this.form = x;
      this.form.kit_id = this.$route.params.id;
    },
    add() {
      this.$http({
        method: "POST",
        url: `/product/add-kit-product/`,
        data: this.form,
      }).then(async (x) => {
        this.$refs["modal-add-product-kit"].hide();
        this.clear();
        await this.$root.$emit("edit-product-complete");
      });
    },
  },
};
</script>
